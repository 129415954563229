@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.title {
  @include h2();
  text-align: center;
  margin: 80px auto;
  line-height: 1.8;
}

.content {
  width: 80%;
  margin: 50px auto;
  min-height: 45vh;
}

.news_header {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  color: $blue;
  font-size: 14px;

  div {
    letter-spacing: 1px;
  }
}

.news_description {
  line-height: 1.8;
  font-size: 16px;
  letter-spacing: 2px !important;
}

.news_img {
  height: 500px;
  width: 85%;
  margin: 0 auto;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 800px) and (min-width: 500px) {
  .title {
    font-size: 14px;
    margin: 50px 0;
  }

  .news_img {
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .content {
    width: 100%;
  }
  .title {
    font-size: 12px;
    margin: 30px auto;
    width: 85%;
  }

  .news_img {
    height: 250px;
    width: 100%;
  }

  .news_header {
    width: 85%;
    margin: 10px auto;
    height: 30px;
  }

  .news_description {
    font-size: 12px;
    width: 85%;
    margin: 10px auto;
  }
}
