@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.h2 {
  font-size: 18px;
  text-align: center;
  margin: 80px auto;
  color: gray;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.8;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
}

.link {
  width: 90%;
  margin: 30px auto !important;
  display: block;
}

.gallery {
  width: 90%;
  margin: 80px auto;
}

.carousel_item {
  height: 250px;
  width: 380px;
  transition: all 0.3s ease-in-out;
}

.carousel_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in;
}

.title {
  text-align: center;
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 1px;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.28);
  line-height: 1.8;
}

.subtitle {
  text-align: center;
  color: gray;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 20px auto;
}

.common_info {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 30px auto;
  color: gray;
}

.content_wrapper {
  background: $silver;
  margin-top: 60px;
  padding: 80px 50px;
}

.content {
  width: 80%;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
}

.content_item {
  position: relative;
  height: 130px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  box-shadow: 0px 2px 5px 1px #c7c7c7;
  -webkit-box-shadow: 0px 2px 5px 1px #c7c7c7;
  -moz-box-shadow: 0px 2px 5px 1px #c7c7c7;
  transition: all 0.3s ease-in;
  border-radius: 15px;
  z-index: 10;
  background: $light-blue;
  color: white;
  font-weight: 600;
  z-index: 1;

  &:hover {
    color: whitesmoke;
    scale: 1.03;
  }
}

.item_img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  z-index: 0;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.icon {
  color: white;
  z-index: 20 !important;
}

.item_title {
  z-index: 12;
  line-height: 1.8;
}

.introduction {
  width: 85%;
  margin: 50px auto;
  line-height: 2;
}

.link {
  margin: 40px;
  color: gray;
  font-size: 12px;
  transition: all 0.3s ease-in;
  display: block;
  text-decoration: none;
  color: inherit;
  &:hover {
    cursor: pointer;
    color: rgb(174, 173, 173);

    .arrowIcon {
      color: rgb(174, 173, 173);
    }
  }
}

.arrowIcon {
  color: $blue;
  margin-right: 10px;
}

.place {
  line-height: 1.7;
}

@media screen and (max-width: 1274px) {
  .content_item {
    min-width: 180px;
  }
  .item_title {
    font-size: 12px;
    width: 75%;
    margin: 0 auto !important;
  }

  .content {
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 950px) {
  .title {
    font-size: 18px;
  }

  .h2 {
    font-size: 14px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 800px) {
  .content {
    display: grid;
    grid-template-columns: 1fr;
  }
  .item_title {
    width: auto;
  }
}

@media screen and (max-width: 550px) {
  .title {
    font-size: 14px;
  }

  .common_info {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em 0;
  }

  .location {
    line-height: 1.8;
  }

  .item_title {
    font-size: 10px;
  }

  .content_item {
    height: 60px;
    &:hover {
      scale: 1.03;
    }
  }
}
