@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.committee_header {
  background: silver;
  height: 80px;
  width: 100%;
}

.h2 {
  @include h2();
  text-align: center;
  margin-top: 100px;
  margin-bottom: 70px;
}

.text {
  width: 85%;
  margin: 20px auto;
  line-height: 2;
}

.text_section {
  div {
    margin: 15px 0;
  }
}

.list_item {
  font-size: 14px;
  padding-left: 40px;
  color: gray;
}
