@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.tarrifs {
  width: 80%;
  margin: 20px auto;
}

.header {
  height: 80px;
  background: silver;
}

.h2 {
  text-align: center;
  @include h2();
  font-size: 18px;
  line-height: 2;
  margin-top: 80px;
}

.table_wrapper {
  width: 80%;
  margin: 80px auto;
}

.subtitle {
  margin: 30px 0;
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 14px;
}

.title {
  color: gray;
  margin: 20px 0;
  letter-spacing: 1px;
  font-weight: 500;
}

.countries {
  width: 85%;
  margin: 50px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, auto));
  gap: 1em;
  justify-content: center;
}

.country {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s ease-in;
  width: 100%;

  &:hover {
    scale: 1.05;
    cursor: pointer;
    color: gray;
  }
}

.countryIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.tariff_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.open {
  cursor: pointer;
  border: none;
  background: none;
  transition: all 0.1s ease-in;
  &:hover {
    color: $blue;
  }
}

.download {
  border: none;
  background: none;
  &:hover {
    color: $blue;
  }
}

@media screen and (max-width: 700px) {
  .table_wrapper {
    width: 90%;
    margin: 80px auto;
  }
  .countryIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .td,
  .open,
  .download {
    font-size: 11px;
    line-height: 1.5;
  }

  .open {
    min-width: 65px;
  }

  .download {
    margin-left: 5px;
  }
}

.table_country {
  display: flex;
  align-items: center;
}
