@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.news {
  position: relative;
  width: 90%;
  height: 300px;
  margin-bottom: 20px;
  border: 5px solid white;
  border-radius: 15px;
  margin: 10px auto;
  box-shadow: 1px 1px 3px 0px rgba(191, 183, 183, 0.75);
  -webkit-box-shadow: 1px 1px 3px 0px rgba(191, 183, 183, 0.75);
  -moz-box-shadow: 1px 1px 3px 0px rgba(191, 183, 183, 0.75);
  transition: all 0.3s ease-in;

  &:hover {
    box-shadow: 1px 2px 10px 0px rgba(191, 183, 183, 0.75);
    -webkit-box-shadow: 1px 2px 10px 0px rgba(191, 183, 183, 0.75);
    -moz-box-shadow: 1px 2px 10px 0px rgba(191, 183, 183, 0.75);
  }
}

.img_wrapper {
  width: 100%;
  height: 200px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(249, 248, 248);
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: all 0.3s ease-in;

  &:hover {
    scale: 1.05;
  }
}

.text {
  height: 150px;
  width: 90%;
  letter-spacing: 1px;
  margin: 0px auto;
  z-index: 11;
  font-size: 14px;
}

.title {
  margin: 15px auto;
  line-height: 1.5;
  letter-spacing: 1px;
  font-weight: 500;
}

.subtitle {
  color: $blue;
  margin-top: 15px;
  font-size: 12px;
}

.date {
  position: absolute;
  top: 5px;
  left: -20px;
  background: $light-blue;
  z-index: 2;
  padding: 5px 10px;
  color: white;
  font-size: 11px;
  letter-spacing: 0.5px;
}

.icon {
  color: $blue;
}

.location {
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  color: gray;
}

@media screen and (max-width: 550px) {
  .news,
  .text {
    height: auto;
  }
}
