.header {
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  justify-content: center;
}

.header2 {
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  position: relative;
  z-index: 1;
  color: white;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 3px 4px 5px 0px rgba(199, 195, 199, 1);
  -moz-box-shadow: 3px 4px 5px 0px rgba(199, 195, 199, 1);
  box-shadow: 3px 4px 5px 0px rgba(199, 195, 199, 1);
}

.header_img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  position: absolute;
  z-index: 0;
}

.logo_img {
  position: relative;
  width: 135px;
  height: 40px;
  margin: 0 50px;
  z-index: 10;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.28);
}

.menu {
  display: flex;
  justify-content: space-between;
  margin-right: 80px;
  position: relative;
  z-index: 10;
}

.menu_item {
  text-decoration: none;
  list-style: none;
  letter-spacing: px;
  font-weight: 500;
  transition: all 0.3s ease-in;
  font-size: 13px;
  &:hover {
    color: lightblue;
  }
}
