@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.header {
  background: silver;
  height: 80px;
  width: 100%;
  position: relative;
}

.img_wrapper {
  width: 100%;
  height: 650px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.h2 {
  @include h2();
  text-align: center;
  padding-top: 40px;
}
