@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.event {
  width: 100%;
  height: 250px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 30px auto;
  position: relative;
  border: 5px solid white;
  box-shadow: 5px 1px 13px 0px rgba(191, 183, 183, 0.75);
  -webkit-box-shadow: 5px 1px 13px 0px rgba(191, 183, 183, 0.75);
  -moz-box-shadow: 5px 1px 13px 0px rgba(191, 183, 183, 0.75);
  transition: all 0.3s ease-in;

  &:hover {
    scale: 1.01;
  }
}

.img_wrapper {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(248, 246, 246);
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  padding: 30px 40px;
}

.subtitle {
  font-size: 14px;
  color: gray;
  margin-bottom: 10px;
  letter-spacing: 1px;
  height: 40px;
}

.title {
  line-height: 1.8;
  letter-spacing: 1px;
  font-size: 19px;
  font-weight: 600;
  margin: 20px 0;
  height: 80px;
}

.header {
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  line-height: 1.5;
  position: absolute;
  bottom: 20px;
}

.date {
  letter-spacing: 0.5px;
}

.icon {
  color: $blue;
}

.location {
  line-height: 1.7;
}

@media screen and (max-width: 1200px) and (min-width: 869px) {
  .title {
    font-size: 16px;
    line-height: 1.8;
  }

  .subtitle,
  .date,
  .location {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 870px) {
  .event {
    grid-template-columns: 1fr;
    height: auto;
  }
}

@media screen and (max-width: 870px) and (min-width: 619px) {
  .text {
    height: auto;
  }

  .header {
    width: 89%;
  }

  .title {
    margin-top: 0;
    font-size: 14.5px;
    margin: 10px auto;
  }

  .subtitle {
    margin-bottom: 0 !important;
    height: 20px;
    font-size: 12px;
  }
}

@media screen and (max-width: 620px) and (min-width: 450px) {
  .header {
    grid-template-columns: 1fr !important;
    height: auto;
    position: relative !important;
    margin-top: 40px;
  }

  .date,
  .location {
    margin-bottom: 10px;
    font-size: 11px;
  }
  .title {
    font-size: 14px;

    margin: 0;
    height: auto;
  }

  .subtitle {
    font-size: 11px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 450px) {
  .img_wrapper {
    height: 150px;
  }

  .text {
    padding: 20px 15px;
  }
  .header {
    grid-template-columns: 1fr !important;
    height: auto;
    position: relative !important;
    margin-top: 40px;
  }

  .date {
    margin-bottom: 10px;
    font-size: 9px;
  }
  .location {
    font-size: 9px;
  }
  .title {
    font-size: 10px;
    margin: 10px auto;
    height: auto;
  }

  .subtitle {
    font-size: 9px;
    height: 20px;
  }
}
