@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.h2 {
  @include h2();
  margin: 80px auto;
  text-align: center;
  line-height: 1.6;
}

.events {
  width: 80%;
  margin: 20px auto;
  padding-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .h2 {
    margin-top: 130px;
    font-size: 18px;
  }
}
