@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.slider {
  width: 100%;
  height: 650px;
  position: relative;
}

.img_wrapper {
  width: 100%;
  height: 100%;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  position: absolute;
  top: 50%;
  left: 80px;
  font-size: 40px;
  color: white;
  letter-spacing: 1px;
  width: 40%;
  font-weight: 600;
  line-height: 1.4;
  z-index: 5;
}

.subtitle {
  font-size: 14px;
  margin: 20px 0;
  font-weight: 400;
  line-height: 0.8;
  color: lightblue;
  letter-spacing: 2px;
}

.header_wrapper {
  position: relative;
}

@media screen and (max-width: 1000px) and (min-width: 700px) {
  .title {
    top: 30%;
    font-size: 30px;
    width: 35%;
    left: 50px;
  }
}

@media screen and (max-width: 699px) and (min-width: 475px) {
  .slider {
    height: 500px;
  }

  .subtitle {
    line-height: 1.5;
    font-size: 14px;
  }
  .title {
    top: 30%;
    font-size: 20px;
    width: 35%;
    left: 20px;
    line-height: 1.7;
  }
}

@media screen and (max-width: 475px) {
  .slider {
    height: 400px;
  }

  .subtitle {
    line-height: 1.5;
    font-size: 11px;
  }
  .title {
    top: 30%;
    font-size: 15px;
    width: 35%;
    left: 20px;
    line-height: 1.7;
  }
}
