@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.h2 {
  @include h2();
  font-size: 20px;
  text-align: center;
  margin: 50px auto;
}

.table {
  width: 70%;
  margin: 50px auto;
}

.link {
  margin: 40px;
  color: gray;
  font-size: 12px;
  transition: all 0.3s ease-in;
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: rgb(174, 173, 173);

    .arrowIcon {
      color: rgb(174, 173, 173);
    }
  }
}

.arrowIcon {
  color: $blue;
  margin-right: 10px;
}

@media screen and (max-width: 1200px) {
  .link {
    margin: 130px auto;
    margin-bottom: 0 !important;
    width: 90%;
    font-size: 10px;
    color: gray;
  }
}

@media screen and (max-width: 650px) {
  .table {
    width: 90%;
    margin: 0 auto;
  }

  .td {
    font-size: 11px;
  }

  .h2 {
    font-size: 16px;
  }
}
