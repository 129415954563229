@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.directions {
  width: 100%;
  height: auto;
  background: $silver;
  padding-bottom: 30px;
}

.inner {
  width: 85%;
  margin: 30px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  gap: 3em;
  justify-content: center;
}

.item {
  display: flex;
  align-items: center;
}

.img_wrapper {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 4px solid white;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in;
  &:hover {
    scale: 1.05;
  }
}

.img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover !important;
  transition: all 0.3s ease-in;
  &:hover {
    scale: 1.05;
  }
}

.text {
  line-height: 1.8;
}

.subtitle {
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
  text-shadow: 0.5px 1px 1px rgba(0, 0, 0, 0.28);
  padding: 20px;
  margin-top: 30px;
  padding-top: 80px;
}
