@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.activityItem {
  position: relative;
  height: 300px;
  width: 300px;
  background: transparent;
  transition: all 0.3s ease-in;
  &:hover {
    scale: 1.02;
  }
}

.text {
  clip-path: polygon(0 13%, 100% 0%, 100% 100%, 0 100%);
  background: $light-blue;
  height: 150px;
  width: 100%;
  position: absolute;
  color: white;
  left: 15px;
  bottom: -10px;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 4px 0px 24px 7px rgba(186, 186, 186, 0.74);
  -moz-box-shadow: 4px 0px 24px 7px rgba(186, 186, 186, 0.74);
  box-shadow: 4px 0px 24px 7px rgba(186, 186, 186, 0.74);
}

.img_wrapper {
  width: 100%;
  height: 200px;
  webkit-box-shadow: 4px 0px 11px 0px rgba(194, 192, 194, 1);
  -moz-box-shadow: 4px 0px 11px 0px rgba(194, 192, 194, 1);
  box-shadow: 4px 0px 11px 0px rgba(194, 192, 194, 1);
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in;
}

.description {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  line-height: 1.5;
  font-weight: 500;
  font-size: 15px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.28);
}

.desc {
  display: flex;
}
