@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";
.team {
  width: 100%;
  margin-top: 50px;
  background: $silver;
  padding: 5px;
  padding-bottom: 80px;
}

.h3 {
  text-align: center;

  @include h2();
  font-size: 17px;
  padding: 50px 0;
  line-height: 1.8;
  width: 90%;
}

.members {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, auto));
  gap: 2em;
  width: 80%;
  margin: 20px auto;
}
