@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.footer {
  min-height: 250px;
  width: 100%;
  position: relative;
  z-index: 0;
  background: #6fcae8;
}

.img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  top: 0;
  left: 0;
  opacity: 0.25;
}

.inner {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  height: 250px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: silver;
}

.logo_img {
  width: 200px;
  height: 60px;
}

.footer_main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.item_title {
  padding: 50px 40px 40px 40px;
  font-weight: 600;
  letter-spacing: 2px;
  color: white;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.28);
}

.item_block {
  padding: 0px 40px;

  div {
    font-size: 14px;
    margin-bottom: 15px;
    letter-spacing: 1.5px;
    font-weight: 500;
    transition: all 0.3s ease-in;
    line-height: 1.8;
  }
}

.icon {
  color: white;
}

@media screen and (max-width: 1000px) and (min-width: 500px) {
  .inner {
    grid-template-columns: 1fr;
    height: 100%;
  }

  .footer_main {
    grid-template-columns: 1fr;
    height: 100%;
  }

  .logo {
    min-height: 200px;
  }

  .footer {
    height: 100% !important;
  }

  .content {
    height: 100% !important;
  }

  .img {
    height: 100% !important;
    object-fit: cover;
  }
  .footer_item:last-child {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 500px) {
  .inner {
    grid-template-columns: 1fr;
    height: 100%;
  }

  .footer_main {
    grid-template-columns: 1fr;

    height: 100%;
  }

  .logo {
    min-height: 100px;
  }
  .logo_img {
    width: 130px;
    height: 40px;
    margin: 0 auto;
  }

  .item_title {
    font-size: 14px;

    padding: 20px;
  }

  .footer_item {
    margin: 5px;
    padding-top: 10px;
  }

  .item_block {
    div {
      font-size: 12px;
      line-height: 1.5;
    }
  }

  .footer {
    height: 100% !important;
  }

  .content {
    height: 100% !important;
  }

  .img {
    height: 100% !important;
    object-fit: cover;
  }
  .footer_item:last-child {
    padding-bottom: 40px;
  }
}
