@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.header {
  height: 80px;
  background: silver;
}

.header_img {
  width: 100%;
  height: 80px;
  object-fit: cover;
}

.h2 {
  @include h2();
  text-align: center;
  margin-top: 100px;
  font-size: 20px;
}

.text {
  width: 80%;
  margin: 50px auto;
  padding-bottom: 30px;

  div {
    margin: 15px 0;
    line-height: 2;
  }
}
