@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.mobileHeader {
  height: 80px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  z-index: 100;
}

.header_img {
  height: 80px;
  width: 100% !important;
  object-fit: cover;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.header_wrapper {
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.logo_wrapper {
  display: block;
}

.logo_img {
  width: 140px;
  height: 40px;
  margin-left: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_text {
  font-size: 12px;
  letter-spacing: 0.5px;
}

.mobile_menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 30px;
  z-index: 10;
  width: 35px;
  height: 25px;

  &:hover {
    .mobile_menu div:first-child,
    .mobile_menu div:last-child {
      background: lightblue;
    }
  }
}

.mobile_menu div:first-child {
  width: 35px;
  height: 1px;
  margin-bottom: 7px;
  border-bottom: 2px solid $light-blue;
  background: white !important;
  position: relative;
  z-index: 100;
}

.mobile_menu div:last-child {
  width: 20px;
  height: 1px;
  margin-bottom: 7px;
  border-bottom: 2px solid $light-blue;
  background: white;
}

@media screen and (max-width: 597px) {
  .logo_text {
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 1.5;
  }
}

.mobile_menu_item {
  display: flex;
  margin: 10px;
  letter-spacing: 0.5px;
  transition: all 0.3s ease-in;

  &:hover {
    color: $light-blue;
  }
}

.icon {
  margin-right: 10px;
  color: $light-blue;
}
