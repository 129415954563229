@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.member {
  width: 100%;
  height: 400px;
  border-radius: 15px;
  border: 5px solid white;
  box-shadow: 5px 1px 13px 0px rgba(191, 183, 183, 0.75);
  -webkit-box-shadow: 5px 1px 13px 0px rgba(191, 183, 183, 0.75);
  -moz-box-shadow: 5px 1px 13px 0px rgba(191, 183, 183, 0.75);
}

.img_wrapper {
  width: 100%;
  height: 240px;
  overflow: hidden;
  border-radius: 15px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in;
  border-radius: 15px !important;
  &:hover {
    scale: 1.05;
  }
}

.member_desc {
  background: white;
  height: 400px - 260px;
  padding: 10px 0;
}

.name {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 20px;
  height: 15px;
}

.country {
  text-align: center;
  color: gray;
  font-size: 11px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto;
  width: 65%;
}

.country_name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag {
  height: 20px;
  width: 25px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.position {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  margin: 10px;
  width: 80%;
  margin: 0 auto;
  letter-spacing: 1px;
  line-height: 1.5;
}

.cv {
  font-size: 10px;
  text-align: center;
  color: $blue;
  margin: 10px 0;
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  &:hover {
    color: gray;
    cursor: pointer;
    .icon {
      color: gray;
    }
  }
}

.icon {
  color: $blue;
  &:hover {
    cursor: pointer;
  }
}
