@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.title {
  @include h2();
  text-align: center;
  margin: 50px auto;
  padding: 20px;
  font-size: 22px;
}

.about_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 350px;
}

.img_wrapper {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  padding: 0 30px;
  line-height: 1.8;

  div {
    margin-bottom: 20px;
  }
}

.subtitle {
  margin: 60px auto;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.28);
  padding: 20px;
  line-height: 2;
}

.activities {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  gap: 4em;
  margin: 50px auto;
  justify-content: center;
}

.intro {
  width: 80%;
  margin: 20px auto;
  line-height: 2;
}

.text_section {
  width: 80%;
  margin: 20px auto;
  line-height: 2;
}

.text_wrapper {
  margin-top: 80px;
  margin-bottom: 100px;
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 18px;
  }

  .about_content,
  .text_section {
    font-size: 14px;
  }
}
