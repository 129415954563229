@import "../../styles/mixins.scss";
@import "../../styles/virables.scss";

.h2 {
  @include h2();
  margin: 80px auto;
  text-align: center;
}

.news {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: 20px auto;
  gap: 2em;
}

@media screen and (max-width: 900px) {
  .news {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

@media screen and (max-width: 1000px) {
  .h2 {
    margin-top: 150px;
  }
}
